<template>
  <VModalWidget
    :dismiss="dismiss"
    :submit="doSubmit"
    :loading="loading"
    :submitting="working"
    :submitTitle="$t('save')"
  >
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-text-field
          v-model.trim="form.firstname"
          :label="$t('firstname')"
          :error-messages="firstnameErrors"
          required
          @input="$v.form.firstname.$touch()"
          @blur="$v.form.firstname.$touch()"
          v-on:keyup.enter="doSubmit"
        />
        <v-text-field
          v-model.trim="form.lastname"
          :label="$t('lastname')"
          :error-messages="lastnameErrors"
          required
          @input="$v.form.lastname.$touch()"
          @blur="$v.form.lastname.$touch()"
          v-on:keyup.enter="doSubmit"
        />
        <v-text-field
          v-model.trim="form.display"
          :label="$t('displayName')"
          :error-messages="displayErrors"
          required
          @input="$v.form.display.$touch()"
          @blur="$v.form.display.$touch()"
          v-on:keyup.enter="doSubmit"
        />
        <FirmField v-model="form.firm_id" :errors="firmIdErrors" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import pick from 'lodash/pick'
import { diffObjects } from '@argon/app/helpers'
import { mapState, mapActions } from 'vuex'
import { BaseForm, FormErrors, VModalWidget } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { required } from 'vuelidate/lib/validators'
import { NAMESPACE } from '@argon/iam/users/store'
import { FirmField } from '../../firms/components'
export default {
  name: 'ModalProfile',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    FormErrors,
    VModalWidget,
    FirmField
  },
  props: {
    userId: { type: [Number, String], required: true }
  },
  validations: {
    form: {
      display: { required },
      firstname: { required },
      lastname: { required },
      firm_id: {}
    }
  },
  data: () => ({
    returnPath: { name: 'systemUsers' },
    form: {},
    loading: true,
    working: false
  }),
  computed: {
    displayErrors() {
      const errors = []
      if (!this.$v.form.display.$dirty) return errors
      !this.$v.form.display.required && errors.push(this.$t('required'))
      this.getServerErrors('display', errors)
      return errors
    },
    firstnameErrors() {
      const errors = []
      if (!this.$v.form.firstname.$dirty) return errors
      !this.$v.form.firstname.required && errors.push(this.$t('required'))
      return errors
    },
    lastnameErrors() {
      const errors = []
      this.getServerErrors('lastname', errors)
      if (!this.$v.form.lastname.$dirty) return errors
      !this.$v.form.lastname.required && errors.push(this.$t('required'))
      return errors
    },
    firmIdErrors() {
      const errors = []
      if (!this.$v.form.firm_id.$dirty) return errors
      this.getServerErrors('firm_id', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getUser', 'patchUser']),
    doSubmit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.working = true
      let data = diffObjects(this.form, this.profile)
      let payload = { userId: this.userId, data }
      this.patchUser(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.working = false
        })
    }
  },
  created() {
    this.getUser(this.userId).then((user) => {
      this.profile = user
      this.form = pick(user, ['firstname', 'lastname', 'display', 'firm_id'])
      this.loading = false
    })
  }
}
</script>

<i18n>
{
  "en": {
    "displayName": "@:labels.displayName",
    "firstname": "@:labels.firstname",
    "lastname": "@:labels.lastname",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "@:labels.profile"
  },
  "fr": {
    "displayName": "@:labels.displayName",
    "firstname": "@:labels.firstname",
    "lastname": "@:labels.lastname",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "@:labels.profile"
  }
}
</i18n>
